
import axios from 'axios';
export default {
    
        async createMoneyMoveTypes(params)  {
            return await axios.post(`money_move_types/create` , params)
        },
        async createMoneyMoveTypesList(params)  {
            return await axios.post(`money_move_types/create/list` , params)
        },
        async updateMoneyMoveTypesColumn(column , value , data)  {
            return await axios.put(`money_move_types/update_list?${column}=${value}` , data)
        },
        async deleteMoneyMoveTypesList(list)  {
            return await axios.delete(`money_move_types/delete_list` , {
                data: {
                    list:list
                }
            })
        },
        async reportMoneyMoveTypes(column , value)  {
            return await axios.get(`money_move_types/report?${column}=${value}`)
        },
        async getAllMoneyMoveTypes()  {
            return await axios.get(`money_move_types/all`)
        },
        async getOneMoneyMoveTypes(money_move_type_id)  {
            return await axios.get(`money_move_types/all/${money_move_type_id}`)
        },
        async getMoneyMoveTypesByColumn(column , value)  {
            return await axios.get(`money_move_types/filter?column=${column}&value=${value}`)
        },
        async deleteMoneyMoveTypes(money_move_type_id)  {
            return await axios.delete(`money_move_types/delete/${money_move_type_id}`)
        },
        async updateMoneyMoveTypes(money_move_type_id , params)  {
            return await axios.put(`money_move_types/update/${money_move_type_id}` , params)
        },
}
        